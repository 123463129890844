import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-yesno',
  templateUrl: './yesno.component.html',
  styleUrls: ['./yesno.component.css']
})
export class YesnoComponent implements OnInit {
  messages: string;
  constructor(private dialogRef: MatDialogRef<YesnoComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.messages = data.message;
  }

  ngOnInit() {
  }

  onSubmitBox(data) {
    this.dialogRef.close(data);
  }
}
