import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiWebService {

  url = 'https://warehousev2.csbjeans.com/';
  //url = 'http://dagstorm.com:8093/';

  header: any;
  constructor(private _http: HttpClient) { }

  postData<T>(postRequestUrl, postData): Observable<T> {
    return this._http.post<T>(this.url + postRequestUrl, postData, {headers: this.header});
  }

  getData<T>(getRequestUrl): Observable<T> {
    return this._http.get<T>(this.url + getRequestUrl, {headers: this.header});
  }

  updateData<T>(putRequestUrl, putData): Observable<T> {
    return this._http.put<T>(this.url + putRequestUrl, putData, {headers: this.header});
  }
}
