import { Product } from './Product';
import { Size } from './Size';

export class SizeOfProd{
    id?: number;
    prodId: number;
    product?: Product;
    sizeId: number;
    size?: Size;
    quantity: number;
    destination?: number;
    quantityDest?: number;
}
