import {Component, OnInit} from '@angular/core';
import {ApiService} from './Service/apiService/api.service';
import {InvoicesPanelComponent} from './invoices-panel/invoices-panel.component';
import {User} from "./models/User";
import {DataService} from "./Service/dataService/data.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'csbMonitoring';

  // tslint:disable-next-line:variable-name
  constructor(private api: ApiService,
    private dataService: DataService) {

  }

  ngOnInit() {
    const user: User = JSON.parse(localStorage.getItem('userCsbMonitoring'));
    if (user) {
      this.dataService.changeMessageUser(user);
      this.dataService.changeMessageNavItem(true);
    }
  }

  changeTo1() {
    this.api.changeUrl1();
  }

  changeTo2() {
    this.api.changeUrl2();
  }
}
