import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {InvoicesPanelComponent} from './invoices-panel/invoices-panel.component';
import {LoginComponent} from './login/login.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { ExchangeComponent } from './components/warehouse/exchange/exchange.component';
import { InputComponent } from './components/warehouse/input/input.component';
import { StateComponent } from './components/warehouse/state/state.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'invoices', component: InvoicesPanelComponent },
  { path: 'warehouse', component: WarehouseComponent,
  children: [
    { path: 'exchange', component: ExchangeComponent },
    { path: 'input', component: InputComponent },
    { path: 'state', component: StateComponent },
    { path: '', redirectTo: '/warehouse/exchange', pathMatch: 'full'}
  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
