import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Invoice} from '../models/Invoice';
import {InvoiceProduct} from '../models/InvoiceProduct';
import {Size} from '../models/Size';
import {Group} from '../models/Group';
import {ApiService} from '../Service/apiService/api.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-invoces-products',
  templateUrl: './invoces-products.component.html',
  styleUrls: ['./invoces-products.component.css']
})
export class InvocesProductsComponent implements OnInit {

  invoice = new Invoice();
  invoiceProducts: InvoiceProduct[];
  groupList: Group[];
  sizeList: Size[];
  sumPrice = 0;

  constructor(private _api: ApiService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.invoice.id = data.ids;
    this.groupList = data.group;
    this.sizeList = data.size;
  }

  ngOnInit() {
    this.getInvoiceFull();
  }

  getInvoiceFull() {
    this._api.getData<InvoiceProduct[]>('invoiceproducts/' + this.invoice.id)
      .subscribe(data => {

        data.forEach(element => {
          this.sumPrice += element.product.price * element.quantity;
        });

        this.invoiceProducts = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

}
