import { Group } from './Group';
import { Size } from './Size';

export class Product{
    id?: number;
    externalId?: string;
    groupId: number;
    group?: Group;
    name: string;
    quantity: number;
    msrp?: number;
    price?: number;
    count?: number;
    delete?: number;
}