import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Materials} from '../../../../models/Materials';
import {Materialgroup} from '../../../../models/Materialgroup';
import {MatPaginator} from '@angular/material/paginator';
import {ReposService} from '../../../../Service/reposService/repos.service';
import {Materialspec} from '../../../../models/Materialspec';
import { Materialspecvalue } from 'src/app/models/Materialspecvalue';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';


@Component({
  selector: 'app-input-material',
  templateUrl: './input-material.component.html',
  styleUrls: ['./input-material.component.css']
})
export class InputMaterialComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  type: boolean;
  material: Materials = new Materials();
  groupForm: FormGroup;
  specsForm: FormGroup;
  materialForm: FormGroup;
  selectionGroup: Materialgroup = new Materialgroup();
  displayedColumns: string[] = ['id', 'name'];
  displayedColumnsSpecs: string[] = ['id', 'name', 'unit', 'remove'];
  displayedColumnsSpecsGroup: string[] = ['id', 'name', 'unit', 'value'];
  dataSource: MatTableDataSource<Materialgroup>;
  dataSourceSpecs: MatTableDataSource<Materialspec>;
  dataSourceSpecsGroup: MatTableDataSource<Materialspec>;
  groups: Array<Materialgroup> = [];
  specs: Array<Materialspec> = [];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('paginatorSpecs', {static: false}) paginatorSpecs: MatPaginator;
  @ViewChild('paginatorSpecsGroup', {static: false}) paginatorSpecsGroup: MatPaginator;

  constructor(public dialogRef: MatDialogRef<InputMaterialComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              public formBuilder: FormBuilder,
              private repos: ReposService,
              private snackBar: MatSnackBar) {
    this.type = data.group;
  }

  ngOnInit() {
    this.groupForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.specsForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      group: ['']
    });

    this.materialForm = this.formBuilder.group({
      name: ['', Validators.required],
      groupId: [null, Validators.required],
      quantity: [null, Validators.required]
    });

    this.getGroup();
  }

  openSnackBar(message: string, state: boolean) {
    const msgState = state ? 'True' : 'False';
    this.snackBar.open(message, msgState, {
      duration: 2500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngAfterViewInit() {
    if (!this.type) {
      this.getMaterialGroups();
    }
  }

  getMaterialGroups() {
    this.repos.getMaterialGroups()
      .subscribe(data => {
        this.groups = data;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterSpecs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceSpecs.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceSpecs.paginator) {
      this.dataSourceSpecs.paginator.firstPage();
    }
  }

  applyFilterSpecsGroup(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceSpecsGroup.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceSpecsGroup.paginator) {
      this.dataSourceSpecsGroup.paginator.firstPage();
    }
  }

  getGroup() {
    this.repos.getMaterialGroups()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      });
  }

  postGroup() {
    if (this.groupForm.valid) {
      const groupM = new Materialgroup();
      groupM.name = this.groupForm.value.name;
      this.repos.postMaterialGroup(groupM)
        .subscribe(data => {
          this.openSnackBar('Grupa je dodata', true);
          this.getGroup();
        });
    }
  }

  postSpecs() {
    if (this.specsForm.valid) {
      const specsM = new Materialspec();
      specsM.groupId = this.selectionGroup.id;
      specsM.name = this.specsForm.value.name;
      specsM.type = this.specsForm.value.type;

      this.repos.postMaterialSpec(specsM)
        .subscribe(data => {
          this.specs.push(data);
          this.dataSourceSpecs = new MatTableDataSource(this.specs);
          this.dataSourceSpecs.paginator = this.paginatorSpecs;

          this.openSnackBar('Specifikacija je unešena', true);
        });
    }
  }

  selectGroup(mGroup: Materialgroup) {
    this.selectionGroup = mGroup;
    this.getSelectionGroupSpecs();
  }

  getSelectionGroupSpecs() {
    this.repos.getSpecsByGroup(this.selectionGroup.id)
      .subscribe(data => {
        this.specs = data;
        this.dataSourceSpecs = new MatTableDataSource(data);
        this.dataSourceSpecs.paginator = this.paginatorSpecs;
      });
  }

  removeSpec(materialSpec: Materialspec) {
    materialSpec.delete = 1;
    this.repos.updateMaterialSpec(materialSpec)
      .subscribe(data => {
        this.specs = this.specs.filter(s => s.id !== data.id);
        this.dataSourceSpecs = new MatTableDataSource(this.specs);
        this.dataSourceSpecs.paginator = this.paginatorSpecs;
        this.openSnackBar('Specifikacija je obrisana', true);
      });
  }

  postMaterial() {
    if (this.materialForm.valid) {
      this.materialForm.value.quantity = parseInt(this.materialForm.value.quantity);
      this.repos.postMaterial(this.materialForm.value)
        .subscribe(data => {
          this.material = data;
          this.getGroupByGroup(data.groupId);
          this.openSnackBar('Materijal je unešen', true);
        });
    }
  }

  getGroupByGroup(groupId: number) {
    this.repos.getSpecsByGroup(groupId)
      .subscribe(data => {
        this.dataSourceSpecsGroup = new MatTableDataSource(data);
        this.dataSourceSpecsGroup.paginator = this.paginatorSpecsGroup;
      });
  }

  saveSpecValues() {

    const specValues = [];
    this.dataSourceSpecsGroup.data.forEach(m => {
      const specValue = new Materialspecvalue();
      specValue.materialId = this.material.id;
      specValue.specId = m.id;
      specValue.value = m.value;

      specValues.push(specValue);
    });

    this.repos.postMaterialSpecValue(specValues)
      .subscribe(data => {
        this.openSnackBar('Specifikacije su izmenjene', true);
      });
  }
}
