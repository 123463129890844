import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'src/app/models/Product';
import { YesnoComponent } from 'src/app/Service/dialogYesNoService/yesno/yesno.component';
import { ReposService } from 'src/app/Service/reposService/repos.service';
import { ViewProductComponent } from './view-product/view-product.component';


@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})
export class ExchangeComponent implements OnInit {

  products: Array<Product>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  pickedSource = 4;
  source = [
    {value: 1, viewValue: 'Radnja 1'},
    {value: 2, viewValue: 'Radnja 2'},
    {value: 3, viewValue: 'Radnja 3'},
    {value: 4, viewValue: 'Magacin'}
  ];

  displayedColumns: string[] = ['id', 'group', 'name', 'msrp', 'price', 'quantity', 'remove'];
  dataSource: MatTableDataSource<Product>;

  constructor(private repos: ReposService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getProducts(this.pickedSource);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getProducts(source) {
    this.repos.getProducts(source)
      .subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    });
  }

  changeSourse(source) {
    this.pickedSource = source;
    this.getProducts(source);
  }

  openDialog(product: Product): void {
    const dialogRef = this.dialog.open(ViewProductComponent, {
      width: '95%',
      height: '85%',
      data: {product: product, source: this.pickedSource}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  removeProduct(product: Product) {
    let status = this.openDialogYesNo(this.pickedSource, product);
    status.afterClosed().subscribe(result => {
      if (result) {
        product.delete = 1;
        this.repos.updateSingleProduct(product, this.pickedSource)
        .subscribe(data => {
          this.getProducts(this.pickedSource);
        });
      }
    });
  }

  openDialogYesNo(destination: any, product: Product) {
    let message = '';
    if (destination === 3) {
      message = `magacinu`;
    } else {
      message = `radnji ${destination}`;
    }

    const dialogRef = this.dialog.open(YesnoComponent, {
      width: '40%',
      height: '20%',
      data: {message: `Želite li da obrišete artikal ${product.name} u ${message} ?`}
    });

    return dialogRef;
  }
}
