import {Materialgroup} from './Materialgroup';

export class Materialspec {
  id: number;
  name: string;
  type: string;
  groupId: number;
  materialgroup: Materialgroup;
  delete?: number;
  value?: string;
}
