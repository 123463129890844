import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs/internal/Observable';
import { Group } from 'src/app/models/Group';
import { Product } from 'src/app/models/Product';
import { Size } from 'src/app/models/Size';
import { SizeOfProd } from 'src/app/models/SizeOfProd';
import { ReposService } from 'src/app/Service/reposService/repos.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {
  pickedSource = 4;
  source = [
    {value: 1, viewValue: 'Radnja 1'},
    {value: 2, viewValue: 'Radnja 2'},
    {value: 3, viewValue: 'Radnja 3'},
    {value: 4, viewValue: 'Magacin'}
  ];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  displayedColumns: string[] = ['size'];
  displayGroupColumns: string[] = ['group'];
  dataSource: any;
  dataSourceG: any;
  size: Size = new Size();
  product: Product = new Product();
  productForm: FormGroup;
  sizeForm: FormGroup;
  sizeProds: Array<SizeOfProd> = [];
  sizeProd: SizeOfProd = new SizeOfProd();
  lsSize: Array<Size> = [];
  groups: Array<Group> = [];
  group: Group = new Group();
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild('paginatorG', {static: true}) paginatorG: MatPaginator;

  constructor(private repos: ReposService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      externalId: [null, Validators.required],
      groupid: [null, Validators.required],
      name: ['', Validators.required],
      msrp: [null, Validators.required],
      price: [null, Validators.required]
    });

    this.sizeForm = this.formBuilder.group({
      size: [null, Validators.required],
      quantity: [null, Validators.required]
    });

    this.getSizes();
    this.getGroups();
  }

  openSnackBar(message: string, state: boolean) {
    const msgState = state ? 'True' : 'False';
    this.snackBar.open(message, msgState, {
      duration: 2500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getGroups() {
    this.repos.getGroup(this.pickedSource)
      .subscribe(data => {
        this.groups = data;
        this.dataSourceG = new MatTableDataSource<Group>(data);
        this.dataSourceG.paginator = this.paginatorG;
      });
  }

  changeSourse(source) {
    this.pickedSource = source;
    this.getSizes();
    this.getGroups();
  }

  getSizes() {
    this.repos.getSizes(this.pickedSource)
    .subscribe(data => {
      this.dataSource = new MatTableDataSource<Size>(data);
      this.dataSource.paginator = this.paginator;
      this.lsSize = data;
    });
  }

  addSize() {
    if (this.lsSize.filter(s => s.size.toLocaleLowerCase() === this.size.size.toLocaleLowerCase())[0]) {
      this.openSnackBar('Veličina već postoji', false);
      return;
    }

    this.repos.postSize(this.size, this.pickedSource)
      .subscribe(data => {
        if (data.id) {
          this.getSizes();
          this.openSnackBar('Veličina je uneta', true);
        }
      });
  }

  syncSize() {
    this.repos.syncSize()
    .subscribe(data => {
      if (data) {
        this.openSnackBar('Veličine su sinhronizovane', true);
      }
    });
  }

  syncGroup() {
    this.repos.syncGroup(this.pickedSource)
      .subscribe(data => {
        this.openSnackBar('Grupe su sinhronizovane', true);
      });
  }

  copy() {
    this.repos.copy(this.product.externalId);
  }

  async paste() {
    this.product.externalId = await this.repos.paste();
  }

  getGuid() {
    this.repos.getGuid()
      .subscribe(data => {
        this.product.externalId = data;
      });
  }

  postProduct() {
    if (this.productForm.valid) {
      var currentDate = Math.floor(Date.now() / 1000);
      this.repos.postProduct(this.pickedSource, this.productForm.value, currentDate)
        .subscribe(data => {
          this.product.id = data.id;
        });
    }
  }

  postProductSize() {
    if (this.sizeForm.valid) {
      this.sizeProd.prodId = this.product.id;
      this.sizeProd.sizeId = this.sizeForm.value['size'];
      this.sizeProd.quantity = parseInt(this.sizeForm.value['quantity']);

      this.repos.postSizeOfProds(this.pickedSource, this.sizeProd)
        .subscribe(data => {
          if (data.id) {
            this.sizeProds.push(data);
          } else {
            this.sizeProds.forEach(e => {
              if (e.sizeId === data.sizeId) {
                e.quantity += data.quantity;
              }
            })
          }
        });
    }
  }

  getSize(id: number) {
    return this.lsSize.filter(sz => sz.id == id)
          .map<string>(s => s.size);
  }

  getSumQuantity() {
    return this.sizeProds.reduce((a, b) => a + b.quantity, 0);
  }

  addGroup() {
    if (this.groups.filter(g => g.group.toLocaleLowerCase() === this.group.group.toLocaleLowerCase())[0]) {
      this.openSnackBar('Grupa već postoji', false);
      return;
    }

    this.repos.postGroup(this.group, this.pickedSource)
      .subscribe(data => {
        this.getGroups();
        this.openSnackBar('Grupa je dodata', false);
      });
  }

  nextProduct() {
    this.product = new Product();
    this.productForm.reset();
    this.sizeProds.length = 0;
  }
}
