import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InvoicesPanelComponent } from './invoices-panel/invoices-panel.component';
import { InvocesProductsComponent } from './invoces-products/invoces-products.component';
import { LoginComponent } from './login/login.component';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SelectCheckAllComponent} from './select-all/select-check-all.component';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {ErrorDialogComponent} from './error-dialog/errordialog.component';
import {ErrorDialogService} from './error-dialog/errordialog.service';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { NavigationComponent } from './components/warehouse/navigation/navigation.component';
import { ExchangeComponent } from './components/warehouse/exchange/exchange.component';
import { InputComponent } from './components/warehouse/input/input.component';
import { StateComponent } from './components/warehouse/state/state.component';
import { ViewProductComponent } from './components/warehouse/exchange/view-product/view-product.component';
import {YesnoComponent} from './Service/dialogYesNoService/yesno/yesno.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatIconModule} from '@angular/material/icon';
import { InputMaterialComponent } from './components/warehouse/state/input-material/input-material.component';
import { ViewMaterialComponent } from './components/warehouse/state/view-material/view-material.component';

@NgModule({
  declarations: [
    AppComponent,
    InvoicesPanelComponent,
    InvocesProductsComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SelectCheckAllComponent,
    DialogBoxComponent,
    ErrorDialogComponent,
    WarehouseComponent,
    NavigationComponent,
    ExchangeComponent,
    InputComponent,
    StateComponent,
    ViewProductComponent,
    YesnoComponent,
    InputMaterialComponent,
    ViewMaterialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgxSpinnerModule,
    MatIconModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    ErrorDialogService
  ],
  bootstrap: [AppComponent],
  entryComponents: [InvocesProductsComponent,
    DialogBoxComponent,
    ErrorDialogComponent,
    ViewProductComponent,
    YesnoComponent,
    InputMaterialComponent,
    ViewMaterialComponent]
})
export class AppModule { }
