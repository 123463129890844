import {Component, OnInit, ViewChild} from '@angular/core';
import {ReposService} from '../../../Service/reposService/repos.service';
import {Materials} from '../../../models/Materials';
import {MatDialog} from '@angular/material/dialog';
import {InputMaterialComponent} from './input-material/input-material.component';
import {MatTableDataSource} from '@angular/material/table';
import {Product} from '../../../models/Product';
import {MatPaginator} from '@angular/material/paginator';
import {ViewMaterialComponent} from './view-material/view-material.component';
import {YesnoComponent} from '../../../Service/dialogYesNoService/yesno/yesno.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  materials: Array<Materials> = [];
  displayedColumns: string[] = ['id', 'group', 'name', 'remove'];
  dataSource: MatTableDataSource<Materials>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private repos: ReposService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getMaterials();
  }

  openSnackBar(message: string, state: boolean) {
    const msgState = state ? 'True' : 'False';
    this.snackBar.open(message, msgState, {
      duration: 2500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openDialog(group: boolean): void {
    const dialogRef = this.dialog.open(InputMaterialComponent, {
      width: '95%',
      height: '90%',
      data: {group: group}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!group) {
        this.getMaterials();
      }
    });
  }

  openDialogMaterial(material: Materials) {
    const dialogRef = this.dialog.open(ViewMaterialComponent, {
      width: '95%',
      height: '90%',
      data: {material: material}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogMaterialYesNo(material: Materials) {

    const dialogRef = this.dialog.open(YesnoComponent, {
      width: '40%',
      height: '20%',
      data: {message: `Da li želite da izbrišete artikal: #${material.id} ${material.name}`}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        material.delete = 1;
        this.updateMaterial(material, material.id);
      }
    });
  }

  getMaterials() {
    this.repos.getMaterials()
      .subscribe(data => {
        this.materials = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateMaterial(material: Materials, id: number) {
    this.repos.updateMaterial(material, id)
      .subscribe(data => {
        this.materials = this.materials.filter(m => m.id !== data.id);
        this.dataSource = new MatTableDataSource(this.materials);
        this.dataSource.paginator = this.paginator;
        this.openSnackBar('Materijal je obrisan', true);
      });
  }
}
