import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { Size } from 'src/app/models/Size';
import { SizeOfProd } from 'src/app/models/SizeOfProd';
import { ReposService } from 'src/app/Service/reposService/repos.service';
import { ExchangeComponent } from '../exchange.component';
import {Group} from '../../../../models/Group';
import {YesnoComponent} from '../../../../Service/dialogYesNoService/yesno/yesno.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  sourceList = [
    {value: 1, viewValue: 'Radnja 1'},
    {value: 2, viewValue: 'Radnja 2'},
    {value: 3, viewValue: 'Radnja 3'},
    {value: 4, viewValue: 'Magacin'}
  ];
  product: Product;
  productForm: FormGroup;
  source: number;
  productItems: Array<SizeOfProd> = [];
  sizeForm: FormGroup;
  sizes: Array<Size> = [];
  sizeProd: SizeOfProd = new SizeOfProd();
  sourceName: string;

  constructor(private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private repos: ReposService,
              public formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<ExchangeComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
      this.product = data.product;
      this.source = data.source;
      this.sourceName = this.sourceList.filter(s => s.value === this.source)[0].viewValue;
      this.sourceList = this.sourceList.filter(s => {
        return s.value !== this.source;
      });
    }

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      id: [this.product.id],
      externalId: [this.product.externalId],
      group: [this.product.group.group],
      name: [this.product.name],
      msrp: [this.product.msrp],
      price: [this.product.price],
      quantity: [this.product.quantity]
    });

    this.sizeForm = this.formBuilder.group({
      size: [null, Validators.required],
      quantity: [null, Validators.required]
    });

    this.repos.getProductItems(this.product.id, this.source)
      .subscribe(data => {
        this.productItems = data;
      });

    this.repos.getSizes(this.source)
      .subscribe(data => {
        this.sizes = data;
      });
  }

  openSnackBar(message: string, state: boolean) {
    const msgState = state ? 'True' : 'False';
    this.snackBar.open(message, msgState, {
      duration: 2500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openDialog(destination: any, product: Product): void {
    let message = '';
    if (destination === 4) {
      message = `magacinu`;
    } else {
      message = `radnji ${destination}`;
    }
    const dialogRef = this.dialog.open(YesnoComponent, {
      width: '40%',
      height: '20%',
      data: {message: `Artikal ne postoji u ${message}, da li želite da ga kreirate?`}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.syncProductGroup(product, destination);
      }
    });
  }

  getGuid() {
    this.repos.getGuid()
      .subscribe(data => {
        this.product.externalId = data;
      });
  }

  saveProduct() {
    if (this.productForm.status === 'VALID') {
      this.productForm.value.group = this.product.group;
      this.productForm.value.groupId = this.product.groupId;
      this.productForm.value.msrp = Number(this.productForm.value.msrp);
      this.productForm.value.price = Number(this.productForm.value.price);
      var currentDate = Math.floor(Date.now() / 1000);
      this.repos.updateProduct(this.productForm.value, this.source, currentDate)
        .subscribe(data => {
          if (!data) {
            alert('Šifra je zauzeta!');
            this.product.externalId = '';
          } else {
            // TODO: indicator after save
          }
        });
    }
  }

  copy() {
    this.repos.copy(this.product.externalId);
  }

  async paste() {
    this.product.externalId = await this.repos.paste();
  }

  postSizePr() {
    if (this.sizeForm.valid) {
      this.sizeProd.prodId = this.product.id;
      this.sizeProd.sizeId = this.sizeForm.value.size;
      this.sizeProd.quantity = parseInt(this.sizeForm.value.quantity);

      this.repos.postSizeOfProds(this.source, this.sizeProd)
        .subscribe(data => {
          data.product = this.product;
          data.size = this.sizes.filter(s => s.id === data.sizeId)[0];

          if (data.id) {
            this.productItems.push(data);
          } else {
            this.productItems.forEach(e => {
              if (e.sizeId === data.sizeId) {
                e.quantity += data.quantity;
              }
            });
          }
        });
    }
  }

  getSumQuantity() {
    return this.productItems.reduce((a, b) => a + b.quantity, 0);
  }

  getSize(id: number) {
    return this.sizes.filter(sz => sz.id === id)
          .map<string>(s => s.size);
  }

  checkDestination(sizeProduct: SizeOfProd) {

    if (!sizeProduct.destination) {
      this.openSnackBar('Odaberite destinaciju za prenos!', false);
      return;
    }

    if (!sizeProduct.quantityDest) {
      this.openSnackBar('Unesite količinu za prenos!', false);
      return;
    }

    if (sizeProduct.quantityDest <= 0) {
      this.openSnackBar('Količina za prenos mora biti veća od 0!', false);
      return;
    }

    if (sizeProduct.quantityDest > sizeProduct.quantity) {
      this.openSnackBar('Količina za prenos mora biti manja od količne pod ovom veličinom!', false);
      return;
    }

    this.repos.checkGroupSizeExist(sizeProduct.destination, this.product.group.group, sizeProduct.size.size)
      .subscribe(respons => {
        if (respons) {
          this.repos.checkDestinationProduct(sizeProduct.destination, this.product.externalId)
            .subscribe(data => {
              if (data) {
                this.transferToDestination(sizeProduct, this.source, sizeProduct.destination);
              } else {
                this.openDialog(sizeProduct.destination, this.product);
              }
            });
        } else {
          this.openSnackBar('Transfer nije izvršen, na drugoj strani ne postoji grupa ili veličina!', false);
        }
      });

    
  }

  syncProductGroup(product: Product, destination: number) {
      this.repos.findGroupId(product.group, destination)
        .subscribe(dataGroup => {
          if (dataGroup) {
            const pr = new Product();
            pr.groupId = dataGroup.id;
            pr.quantity = 0;
            pr.externalId = product.externalId;
            pr.msrp = product.msrp;
            pr.price = product.price;
            pr.name = product.name;

            var currentDate = Math.floor(Date.now() / 1000);
            this.createProduct(pr, destination, currentDate);
          }
      });
  }

  createProduct(product: Product, destination: number, date: number) {
    this.repos.postProduct(destination, product, date)
      .subscribe(data => {
        // TODO: alert product is posted
        this.openSnackBar('Artikal je upisan', true);
      });
  }

  transferToDestination(sizeProduct: SizeOfProd, source: number, destination: number) {
    sizeProduct.product = this.product;
    
    const sz = new SizeOfProd();
    sz.id = sizeProduct.id;
    sz.product = this.product;
    sz.quantity = sizeProduct.quantityDest;
    sz.prodId = this.product.id;
    sz.sizeId = sizeProduct.sizeId;
    sz.size = sizeProduct.size;

    this.repos.transferProducts(sz, source, destination)
            .subscribe(data => {
              this.openSnackBar('Transfer je uspešno izvršen', true);
              sizeProduct.quantity -= sizeProduct.quantityDest;
            });
    }
}
