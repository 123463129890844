import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Materials} from '../../../../models/Materials';
import {ReposService} from '../../../../Service/reposService/repos.service';
import {Materialspecvalue} from '../../../../models/Materialspecvalue';
import {Materialspec} from '../../../../models/Materialspec';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Materialgroup} from '../../../../models/Materialgroup';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-view-material',
  templateUrl: './view-material.component.html',
  styleUrls: ['./view-material.component.css']
})
export class ViewMaterialComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  material: Materials;
  materialForm: FormGroup;
  materialSpec: Array<Materialspec> = [];
  materialSpecValue: Array<Materialspecvalue> = [];
  displayedColumns: string[] = ['id', 'name', 'unit', 'value'];
  dataSource: MatTableDataSource<Materialspec>;
  edit = false;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ViewMaterialComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private repos: ReposService,
              public formBuilder: FormBuilder,
              private snackBar: MatSnackBar) {
    this.material = data.material;
  }

  ngOnInit() {
    this.getMaterialsSpecs();

    this.materialForm = this.formBuilder.group({
      name: [this.material.name, Validators.required],
      group: [this.material.materialgroup.name, Validators.required],
      quantity: [this.material.quantity, Validators.required]
    });
  }

  openSnackBar(message: string, state: boolean) {
    const msgState = state ? 'True' : 'False';
    this.snackBar.open(message, msgState, {
      duration: 2500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getMaterialsSpecs() {
    this.repos.getSpecsByGroup(this.material.groupId)
      .subscribe(data => {
        this.materialSpec = data;
        this.getMaterialSpecValues(this.material.id);
      });
  }

  getMaterialSpecValues(id: number) {
    this.repos.getMaterialSpecValue(id)
      .subscribe(data => {
        this.materialSpecValue = data;

        if (this.materialSpecValue.length > 0) {
          this.materialSpec.forEach(m => {
            m.value = this.getSpecValues(m.id);
          });
        }

        this.dataSource = new MatTableDataSource(this.materialSpec);
        this.dataSource.paginator = this.paginator;
      });
  }

  getSpecValues(specId: number) {
    return this.materialSpecValue.filter(s => s.specId === specId)
      .map(s => s.value)[0];
  }

  updateMaterial() {
    if (this.materialForm.valid) {
      this.material.name = this.materialForm.value.name;
      this.material.quantity = this.materialForm.value.quantity;

      this.repos.updateMaterial(this.material, this.material.id)
        .subscribe(data => {
          this.openSnackBar('Materijal je izmenjen', true);
        });
    }
  }

  editSpecsValue() {
    const specs = [];
    this.dataSource.data.forEach(s => {

      const spec = new Materialspecvalue();
      spec.value = s.value;
      spec.specId = s.id;
      spec.materialId = this.material.id;
      spec.id = this.getSpecValueId(this.material.id, s.id);

      specs.push(spec);
    });

    this.repos.updateMaterialSpecValue(specs)
      .subscribe(data => {
        this.openSnackBar('Karakteristike materijala su izmenjene', true);
      });
  }

  getSpecValueId(materialId: number, specId: number) {
    return this.materialSpecValue.filter(m => m.materialId === materialId && m.specId === specId)
      .map(m => m.id)[0];
  }
}
