import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../Service/apiService/api.service";
import {DataService} from "../../Service/dataService/data.service";
import {Router} from "@angular/router";
import {User} from "../../models/User";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: User = new User();
  lockHeadersItem: boolean;
  constructor(private _api: ApiService,
              private _dataService: DataService,
              private _router: Router) {
  }

  ngOnInit() {
    this._dataService.currentMessageNavItem.subscribe(lock =>  this.lockHeadersItem = lock);
  }

  logout() {
    localStorage.removeItem('userCsbMonitoring');
    localStorage.removeItem('tokenCsbMonitoring');
    this._dataService.changeMessageUser(new User());
    this._dataService.changeMessageNavItem(false);
    this._router.navigate(['/']);
  }
}
