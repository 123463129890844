import {Materialgroup} from './Materialgroup';

export class Materials {
  id: number;
  name: string;
  groupId: number;
  materialgroup: Materialgroup;
  quantity: number;
  delete?: number;
}
