import { User } from './User';

export class Invoice{
    id?: number;
    userId: number;
    user?: User;
    dateInvoice: number;
    sumPrice?: number;
    delete?: number;
    write?: number;
}