import { Injectable } from '@angular/core';
import { Group } from 'src/app/models/Group';
import { Product } from 'src/app/models/Product';
import { Size } from 'src/app/models/Size';
import { SizeOfProd } from 'src/app/models/SizeOfProd';
import { ApiWebService } from '../apiService/api-web.service';
import { ApiService } from '../apiService/api.service';
import {Materials} from '../../models/Materials';
import {Materialgroup} from '../../models/Materialgroup';
import {Materialspec} from '../../models/Materialspec';
import {Materialspecvalue} from '../../models/Materialspecvalue';

@Injectable({
  providedIn: 'root'
})
export class ReposService {

  constructor(private apiWH: ApiWebService,
    private api: ApiService) { }

  getProducts(source) {
    return this.apiWH.getData<Product[]>(`transfer/${source}`);
  }

  getGuid() {
    return this.apiWH.getData<string>(`transfer/guid`);
  }

  updateProduct(product: Product, sourse: number, date: number) {
    return this.apiWH.updateData<Product>(`transfer/product/${sourse}/${date}`, product);
  }

  copy(copyData: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyData;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async paste() {
    return await navigator.clipboard.readText();
  }

  getProductItems(id: number, source: number) {
    return this.apiWH.getData<SizeOfProd[]>(`transfer/product/items/${id}/${source}`);
  }

  getSizes(source: number) {
    return this.apiWH.getData<Size[]>(`transfer/sizes/${source}`);
  }

  postSize(size: Size, source: number) {
    return this.apiWH.postData<Size>(`transfer/sizes/${source}`, size);
  }

  syncSize() {
    return this.apiWH.getData<Boolean>(`transfer/sync/sizes`);
  }

  getGroup(source: number) {
    return this.apiWH.getData<Group[]>(`transfer/groups/${source}`);
  }

  postProduct(source: number, product: Product, dateCreated: number) {
    return this.apiWH.postData<Product>(`transfer/product/${source}/${dateCreated}`, product);
  }

  postSizeOfProds(source: number, sizePr: SizeOfProd) {
    return this.apiWH.postData<SizeOfProd>(`transfer/sizeproduct/${source}`, sizePr);
  }

  checkDestinationProduct(destination: number, id: string) {
    return this.apiWH.getData<Product>(`transfer/check/destination/${destination}/${id}`);
  }

  checkGroupSizeExist(destination: number, group: string, size: string) {
    return this.apiWH.getData<Boolean>(`transfer/check/product/attributes/${destination}/${group}/${size}`);
  }

  findSizeId(size: Size, destination: number) {
    return this.apiWH.postData<Size>(`transfer/size/findid/${destination}`, size);
  }

  findGroupId(group: Group, destination: number) {
    return this.apiWH.postData<Group>(`transfer/groups/findid/${destination}`, group);
  }

  transferProducts(sizeProduct: SizeOfProd, source: number, destination: number) {
    return this.apiWH.postData<SizeOfProd>(`transfer/source/${source}/destination/${destination}`, sizeProduct);
  }

  postGroup(group: Group, source: number) {
    return this.apiWH.postData<Group>(`transfer/groups/${source}`, group);
  }

  syncGroup(source: number) {
    return this.apiWH.getData<Group[]>(`transfer/groups/sync/${source}`);
  }

  updateSingleProduct(product: Product, source: number) {
    return this.apiWH.postData<Product>(`transfer/single/product/${source}`, product);
  }

  getMaterials() {
    return this.apiWH.getData<Materials[]>(`material/materials`);
  }

  getMaterialGroups() {
    return this.apiWH.getData<Materialgroup[]>(`material/groups`);
  }

  getSpecs(id: number) {
    return this.apiWH.getData<Materialspec[]>(`material/specs/${id}`);
  }

  postMaterial(material: Materials) {
    return this.apiWH.postData<Materials>(`material/materials`, material);
  }

  getSpecsByGroup(groupId: number) {
    return this.apiWH.getData<Materialspec[]>(`material/specs/group/${groupId}`);
  }

  postMaterialGroup(group: Materialgroup) {
    return this.apiWH.postData<Materialgroup>(`material/group`, group);
  }

  postMaterialSpec(specs: Materialspec) {
    return this.apiWH.postData<Materialspec>(`material/specs`, specs);
  }

  postMaterialSpecValue(specValue: Materialspecvalue[]) {
    return this.apiWH.postData<Materialspecvalue>(`material/specs/value/`, specValue);
  }

  updateMaterialSpec(materialSpec: Materialspec) {
    return this.apiWH.updateData<Materialspec>(`material/specs/${materialSpec.id}`, materialSpec);
  }

  updateMaterialSpecValue(materialSpecValue: Materialspecvalue[]) {
    return this.apiWH.updateData<Materialspecvalue>(`material/specs/value/`, materialSpecValue);
  }

  getMaterialSpecValue(id: number) {
    return this.apiWH.getData<Materialspecvalue[]>(`material/specs/material/${id}`);
  }

  updateMaterial(material: Materials, id: number) {
    return this.apiWH.updateData<Materials>(`material/${id}`, material);
  }
}
