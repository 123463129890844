import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {User} from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  urlServerApi = 'https://storeone.csbjeans.com/api/';

  user: User = new User();
  // tslint:disable-next-line:variable-name
  constructor(private _http: HttpClient) {
  }

  postData<T>(postRequestUrl, postData): Observable<T> {
    return this._http.post<T>(this.urlServerApi + postRequestUrl, postData);
  }

  getData<T>(getRequestUrl): Observable<T> {
    return this._http.get<T>(this.urlServerApi + getRequestUrl);
  }

  updateData<T>(putRequestUrl, putData): Observable<T> {
    return this._http.put<T>(this.urlServerApi + putRequestUrl, putData)
      .pipe(map(resp => resp));
  }

  changeUrl1() {
    this.urlServerApi = 'https://storeone.csbjeans.com/api/';
  }

  changeUrl2() {
    this.urlServerApi = 'https://storetwo.csbjeans.com/api/';
  }
  
  changeUrl3() {
    this.urlServerApi = 'https://storethree.csbjeans.com/api/';
  }

  changeUrl4() {
    this.urlServerApi = 'https://storefour.csbjeans.com/api/';
  }
}
