import { Component, OnInit } from '@angular/core';
import {User} from '../models/User';
import {ApiService} from '../Service/apiService/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import {DataService} from '../Service/dataService/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: User = {
    userName: '',
    password: ''
  };

  loginSave: boolean;
  // tslint:disable-next-line:variable-name
  constructor(private _apiService: ApiService,
              private _router: Router,
              private _dataService: DataService) {
  }

  ngOnInit() {
    localStorage.removeItem('userCsbMonitoring');
    localStorage.removeItem('tokenCsbMonitoring');
    this._dataService.changeMessageUser(new User());
    this._dataService.changeMessageNavItem(false);

    const userTmp: User = JSON.parse(localStorage.getItem('saveLoginCsbData'));
    if (userTmp) {
      this.user = userTmp;
      this.loginSave = true;
    }
  }

  login() {
    this._apiService.postData<User>('users/authenticate', this.user)
      .subscribe(response => {
        localStorage.setItem('userCsbMonitoring', JSON.stringify(response));
        localStorage.setItem('tokenCsbMonitoring', response.token);
        this._dataService.changeMessageUser(response);
        this._dataService.changeMessageNavItem(true);
        if (this.loginSave) {
          localStorage.setItem('saveLoginCsbData', JSON.stringify(this.user));
        }
        this._router.navigate(['/invoices']);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

}
