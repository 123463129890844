export class User{
    id?: number;
    firstName?: string;
    lastName?: string;
    userName: string;
    phoneNumber?: string;
    password: string;
    level?: number;
    delete?: number;
    lastAccess?: number;
    token?: string;
}
