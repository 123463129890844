import {Component, OnInit, ViewChild} from '@angular/core';
import {Invoice} from '../models/Invoice';
import {ApiService} from '../Service/apiService/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material';
import {Group} from '../models/Group';
import {User} from '../models/User';
import {Size} from '../models/Size';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {InvocesProductsComponent} from '../invoces-products/invoces-products.component';
import {Router} from '@angular/router';
import {DataService} from '../Service/dataService/data.service';

export interface Store {
  id: number;
  name: string;
}

@Component({
  selector: 'app-invoices-panel',
  templateUrl: './invoices-panel.component.html',
  styleUrls: ['./invoices-panel.component.css']
})
export class InvoicesPanelComponent implements OnInit {

  store: Store[] = [
    { id: 0, name: 'Prodavnica 1'},
    { id: 1, name: 'Prodavnica 2'},
    { id: 3, name: 'Prodavnica 3'},
    { id: 4, name: 'Prodavnica 4'},
  ];

  displayedColumns: string[] = ['Id', 'User', 'Time', 'Sum', 'Invoice'];
  invoiceList: Invoice[] = new Array();
  dataSource = new MatTableDataSource<Invoice>(this.invoiceList);
  groupList: Group[];
  sizeList: Size[];
  sumInvoice = 0;
  usersList: User[];
  user: User;
  sumEarning: number;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _api: ApiService,
              public dialog: MatDialog,
              private _router: Router,
              private _dataService: DataService) {
  }

  ngOnInit() {
      this.getInvoiceDaily();
      this.getAllSize();
      this.getAllGroup();
      this.getAllUsers();
  }

  openInvoicesDialog(id): void {
    const dialogRef = this.dialog.open(InvocesProductsComponent, {
      width: '80%',
      data: {ids: id, size: this.sizeList, group: this.groupList}
    });
  }

  getInvoiceDaily() {
    this._api.getData<Invoice[]>('invoices/daily')
      .subscribe(data => {
        this.invoiceList = data;
        this.dataSource = new MatTableDataSource<Invoice>(this.invoiceList);
        this.dataSource.paginator = this.paginator;
        this.getSumInvoices();
      }, (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 401) {
          //this. _router.navigate(['/']);
        }
      });
  }

  getSumInvoices() {
    this.sumInvoice = 0;
    this.invoiceList.forEach(element => {
      this.sumInvoice += element.sumPrice;
    });
    this.getInvoiceEarnings();
  }

  getInvoiceEarnings() {
    const invIds = [];

    this.invoiceList.forEach(element => {
      invIds.push(element.id);
    });

    this._api.postData<number>('invoices/sumEarning', invIds)
      .subscribe(data => {
        this.sumEarning = this.sumInvoice - data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getAllSize() {
    this._api.getData<Group[]>('groups')
      .subscribe(data => {
        this.groupList = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getAllGroup() {
    this._api.getData<Size[]>('sizes')
      .subscribe(data => {
        this.sizeList = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getAllUsers() {
    this._api.getData<User[]>('users')
      .subscribe(data => {
        this.usersList = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getInvoiceMonthly() {
    this._api.getData<Invoice[]>('invoices/monthly')
      .subscribe(data => {
        this.invoiceList = data;
        this.dataSource = new MatTableDataSource<Invoice>(this.invoiceList);
        this.dataSource.paginator = this.paginator;
        this.getSumInvoices();
      }, (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 401) {
          this. _router.navigate(['/']);
        }
      });
  }

  getInvoiceYearly() {
    this._api.getData<Invoice[]>('invoices/yearly')
      .subscribe(data => {
        this.invoiceList = data;
        this.dataSource = new MatTableDataSource<Invoice>(this.invoiceList);
        this.dataSource.paginator = this.paginator;
        this.getSumInvoices();
      }, (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 401) {
          //this. _router.navigate(['/']);
        }
      });
  }

  changeStoreUrl(id) {

    if (id === 0) {
      this._api.changeUrl1();
      this.getInvoiceDaily();
      this.getAllUsers();
    } else if (id === 1) {
      this._api.changeUrl2();
      this.getInvoiceDaily();
      this.getAllUsers();
    } else if (id === 3) {
      this._api.changeUrl3();
      this.getInvoiceDaily();
      this.getAllUsers();
    } else if (id === 4) {
      this._api.changeUrl4();
      this.getInvoiceDaily();
      this.getAllUsers();
    }

  }
}
