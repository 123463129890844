import { Injectable } from '@angular/core';
import {User} from '../../models/User';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  messageSourceAttr = new BehaviorSubject<any>(null);
  currentMessage = this.messageSourceAttr.asObservable();

  messageSourceNavItem = new BehaviorSubject<boolean>(null);
  currentMessageNavItem = this.messageSourceNavItem.asObservable();

  messageSourceUser = new BehaviorSubject<User>(null);
  currentMessageUser = this.messageSourceUser.asObservable();

  constructor() { }

  changeMessageAttr(message) {
    this.messageSourceAttr.next(message);
  }

  changeMessageNavItem(message) {
    this.messageSourceNavItem.next(message);
  }

  changeMessageUser(message) {
    this.messageSourceUser.next(message);
  }
}
